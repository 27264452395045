import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faBackspace, faBuilding } from '@fortawesome/free-solid-svg-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const LOGINURL = 'https://duoifa9w7c.execute-api.ap-northeast-1.amazonaws.com/v1/login';
const AUTHURL = 'https://duoifa9w7c.execute-api.ap-northeast-1.amazonaws.com/v1/auth';
const CALLURL = 'https://duoifa9w7c.execute-api.ap-northeast-1.amazonaws.com/v1/call';
export const useDidMount = (func) => useEffect(() => { func() }, []);

document.addEventListener('touchmove', (event) => {
  if (event.scale !== 1) { event.preventDefault(); }
}, false);

let lastTouchEnd = 0;
document.addEventListener('touchend', (event) => {
  let now = (new Date()).getTime();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, false);

function App() {
  const [loginPhoneNumber, setLoginPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [callError, setCallError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [to, setTo] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useDidMount(() => {
    const node = document.getElementById('phone');
    if (node !== null) {
      node.focus();
    }
    const node2 = document.getElementById('to');
    if (node2 !== null) {
      node2.focus();
    }
  });

  useEffect(() => {
    if (loginPhoneNumber) {
      setTimeout(() => {
      const node = document.getElementById('code');
      node.value = null;
      }, 300);
    }
  }, [loginPhoneNumber]);

  const handleUpdate = async (e) => {
    setShowSpinner(true);
    setPhoneError('');
    const tryPhoneNumber = document.getElementById('phone').value;
    console.log(tryPhoneNumber);
    try {
      const phoneNumber = parsePhoneNumberFromString(tryPhoneNumber, 'JP');
      const loginRes = await axios.post(LOGINURL, {phone_number: phoneNumber.number});
      setLoginPhoneNumber(phoneNumber.number);
      document.getElementById('phone').value = '';
    } catch(e) {
      setPhoneError('電話番号が違います');
    }
    setShowSpinner(false);
  };

  const handleCodeUpdate = async(e) => {
    setShowSpinner(true);
    setCodeError('');
    try {
      const tryCode = document.getElementById('code').value;
      const authRes = await axios.post(AUTHURL, {phone_number: loginPhoneNumber, code: tryCode});
      setCode(tryCode);
      document.getElementById('code').value = '';
    } catch(e) {
      setCodeError('確認コードが違います');
    }
    setShowSpinner(false);
  };

  const handleChange = async (e) => {
    if (e.key === 'Enter') {
      handleUpdate(e);
    }
  };

  const handleCode = async (e) => {
    if (e.key === 'Enter') {
      handleCodeUpdate(e);
    }
  };

  const handleButton = async (e) => {
    console.log(e.target.innerText);
    setTo(`${to}${e.target.innerText}`);
  };

  const handleCall = async () => {
    let callTo;
    try {
      if (to === '都庁') {
        callTo = '+818054694667';
      } else {
        const phoneNumber = parsePhoneNumberFromString(to, 'JP');
        callTo = phoneNumber.number;
      }
      setShowModal(true);
      const callRes = await axios.post(CALLURL, {to: callTo, from: loginPhoneNumber});
    } catch(e) {
      setShowModal(false);
      setCallError('電話番号に誤りがあります');
    }
  };

  const handleDelete = () => {
    if (to) {
      let ar = to.split('');
      ar.pop();
      setTo(ar.join(''));
    }
  };

  //if (false) {
  if (!loginPhoneNumber) {
    return (
      <div className="App">
        <header className="App-header">
          {phoneError && <p className="Error">{phoneError}</p>}
          <p><b>あなたの</b>電話番号</p>
          {showSpinner && <Spinner animation="border" variant="info" size="sm" />}
          <input id="phone" type="tel" maxLength="20" className="Input-phone" defaultValue={""} onBlur={handleUpdate} onKeyPress={handleChange} /> 
        </header>
      </div>
    );
  } else if (!code) {
    return (
      <div className="App">
        <header className="App-header">
          <p>Slackに送信された確認コードを<br />こちらに記入してください</p>
          {codeError && <p className="Error">{codeError}</p>}
          <input id="code" maxLength="6" type="tel" className="Input-phone" defaultValue={""} onBlur={handleCodeUpdate} onKeyPress={handleCode} />
        </header>
      </div>
    );
  } else {
    return <div className="App">
      <div>
        <input style={{border:'none'}} type="tel" id="to" className="Input-phone" onChange={(e) => {setTo(e.target.value);}} value={to} />
        {callError && <p className="Error">{callError}</p>}
      </div>
      <div>
        <table>
          <tbody>
          <tr>
            <td><button className="dial" onClick={handleButton}>1</button></td>
            <td><button className="dial" onClick={handleButton}>2</button></td>
            <td><button className="dial" onClick={handleButton}>3</button></td>
          </tr>
          <tr>
            <td><button className="dial" onClick={handleButton}>4</button></td>
            <td><button className="dial" onClick={handleButton}>5</button></td>
            <td><button className="dial" onClick={handleButton}>6</button></td>
          </tr>
          <tr>
            <td><button className="dial" onClick={handleButton}>7</button></td>
            <td><button className="dial" onClick={handleButton}>8</button></td>
            <td><button className="dial" onClick={handleButton}>9</button></td>
          </tr>
          <tr>
            <td>{false && <button className="dial" onClick={() => {
              setTo('都庁');
            }}><FontAwesomeIcon icon={faBuilding} /></button>}</td>
            <td><button className="dial" onClick={handleButton}>0</button></td>
            <td></td>
          </tr>
          </tbody>
        </table>
        <div style={{display:'flex',marginTop:'10px'}}>
          <div style={{flex:'0.5',textAlign:'center'}}>
            <button className="dial" style={{fontSize:'36px'}} onClick={handleCall}>
              <FontAwesomeIcon style={{color:'green'}} icon={faPhoneVolume} />
            </button>
          </div>
          <div style={{flex:'0.5',textAlign:'center'}}>
            <button className="dial" style={{fontSize:'36px'}} onClick={handleDelete}>
              <FontAwesomeIcon style={{color:'red'}} icon={faBackspace} />
            </button></div>
        </div>
      </div>
      <Modal show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>通話を開始します</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <p>あなたの携帯電話を呼び出しています</p>
        </Modal.Body>
      
        <Modal.Footer>
          <Button onClick={() => {setShowModal(false);}} variant="primary">閉じる</Button>
        </Modal.Footer>
      </Modal>
    </div>
  }
}

export default App;
